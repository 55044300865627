import React from 'react';
import Head from 'next/head';
import Link from 'next/link';
import PropTypes from 'prop-types';

import { useStore as historyStore } from '../../contexts/history';
import styles from './layout.module.scss';
import useLanguage from '../_hooks/useLanguage';
import DocumentHead from '../_system/head';

const LayoutAuth = ({ children, bodyClass, metaTitle }) => {
	const { t } = useLanguage();
	const { goBack } = historyStore();

	const bClass = ['container-fluid px-0', styles.auth];
	if (bClass) bClass.push(bodyClass);

	return (
		<>
			<DocumentHead />
			<div className={bClass.join(' ')}>
				<div className="row mx-0">
					<div className={`col-lg-6 px-0 ${styles.left}`}>
						<div className={styles.hero}>
							<Link href="/"><a className={styles.logo} /></Link>
							<div className={styles.aligned}>
								<h1 dangerouslySetInnerHTML={{ __html: t('authHeroTitle') }} />
								<p dangerouslySetInnerHTML={{ __html: t('authHeroText') }} />
								<ul dangerouslySetInnerHTML={{ __html: t('authHeroFeatures') }} />
								<Link href="/mojedrazbe-pro"><a className="btn white">{t('btnReadMore')}</a></Link>
							</div>
						</div>
					</div>
					<div className={`col-lg-6 px-0 ${styles.main}`}>
						<div className={styles.aligned}>
							<Link href="/"><a className={styles.logo} /></Link>
							<button type="button" onClick={() => goBack()} className={styles.goBack}>x</button>
							{children}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

LayoutAuth.defaultProps = {
	bodyClass: null,
	metaTitle: null
};

LayoutAuth.propTypes = {
	children: PropTypes.node.isRequired,
	bodyClass: PropTypes.string,
	metaTitle: PropTypes.string
};

export default LayoutAuth;
